import React from "react"
import "./footer.css"
import logo from "../../static/logo48.png"
import Dict from "../langs/dict"
import { Link } from "gatsby"

export default props => {
  const dict = new Dict(props.lang)
  return (
    <div id="footer-background">
      <div id="footer-holder">
        <div className="footer-col">
          <h6>{dict.getTrans("foot_col1_title")}</h6>
          <ul>
            <li>{dict.getTrans("foot_col1_1")}</li>
            <li>{dict.getTrans("foot_col1_2")}</li>
          </ul>
        </div>
        <div className="footer-col">
          <h6>{dict.getTrans("foot_col2_title")}</h6>
          <ul>
            <a
              href={dict.getTrans("foot_col2_1_link")}
              target="_blank"
              rel="noopener"
            >
              <li>{dict.getTrans("foot_col2_1")}</li>
            </a>
            <a
              href={dict.getTrans("foot_col2_2_link")}
              target="_blank"
              rel="noopener"
            >
              <li>{dict.getTrans("foot_col2_2")}</li>
            </a>
            <a
              href={dict.getTrans("foot_col2_3_link")}
              target="_blank"
              rel="noopener"
            >
              <li>{dict.getTrans("foot_col2_3")}</li>
            </a>
          </ul>
        </div>
        <div className="footer-col">
          <h6>{dict.getTrans("foot_col3_title")}</h6>
          <ul>
            <Link to={dict.getTrans("foot_col3_1_link")}>
              <li>{dict.getTrans("foot_col3_1")}</li>
            </Link>
            <Link to={dict.getTrans("foot_col3_2_link")}>
              <li>{dict.getTrans("foot_col3_2")}</li>
            </Link>
            <Link to={dict.getTrans("foot_col3_3_link")}>
              <li>{dict.getTrans("foot_col3_3")}</li>
            </Link>
            <Link to={dict.getTrans("foot_col3_4_link")}>
              <li>{dict.getTrans("foot_col3_4")}</li>
            </Link>
          </ul>
        </div>
        <div id="footer-intro">
          <p>{dict.getTrans("foot_text")}</p>
          <div id="copyright">
            <p>© Copyright webpanda.ch 2018-2019</p>
            <img id="logo" alt="logo" className="offset-md-1" src={logo} />
          </div>
        </div>
      </div>
    </div>
  )
}
