export default class Dict {
  constructor(lang) {
    this.lang = lang
    this.translations = {
      navbar_1: {
        en: "Services",
        fr: "Services",
      },
      navbar_2: {
        en: "Works",
        fr: "Projets",
      },
      navbar_3: {
        en: "Team",
        fr: "Team",
      },
      navbar_cta: {
        en: "Contact us",
        fr: "Contact",
      },
      navbar_lang1: {
        en: "EN",
        fr: "EN",
      },
      navbar_lang2: {
        en: "FR",
        fr: "FR",
      },
      navbar_lang3: {
        en: "DE",
        fr: "DE",
      },
      navbar_link_home: {
        en: "/",
        fr: "/fr/",
      },
      navbar_link1: {
        en: "/services",
        fr: "/fr/services",
      },
      navbar_link2: {
        en: "/works",
        fr: "/fr/works",
      },
      navbar_link3: {
        en: "/team",
        fr: "/fr/team",
      },
      you_bring: {
        en: "You bring the ",
        fr: "Donnez ",
      },
      idea: {
        en: "idea",
        fr: "vie",
      },
      we_bring: {
        en: "We bring it to ",
        fr: "A vos ",
      },
      life: {
        en: "life",
        fr: "idées",
      },
      we_are: {
        en:
          "We are a Swiss agency specialized in all things digital. Let us help you with your next",
        fr:
          "Nous sommes une agence suisse spécialisée dans le digital. Travaillons ensemble sur votre prochain",
      },
      website: {
        en: "website",
        fr: "site web",
      },
      application: {
        en: "application",
        fr: "application",
      },
      digital_strategy: {
        en: "digital strategy",
        fr: "stratégie digitale",
      },
      we_are_connector: {
        en: " or ",
        fr: " ou ",
      },
      our_work_title: {
        en: "Our work.",
        fr: "Projets.",
      },
      our_work_text: {
        en:
          "Discover a selection of our latest works and get inspired. We love helping our clients bring their ideas to life.",
        fr:
          "Découvrez une sélection de nos dernières réalisations. Donnez vie aux idées de nos client, c'est ce qu'on fait le mieux.",
      },
      work_filter_1: {
        en: "All works",
        fr: "Tous",
      },
      work_filter_2: {
        en: "Websites",
        fr: "Sites Web",
      },
      work_filter_3: {
        en: "Applications",
        fr: "Applications",
      },
      work_filter_4: {
        en: "Design",
        fr: "Design",
      },
      work_more: {
        en: "All Works >",
        fr: "Tous nos projets >",
      },
      work_more_link: {
        en: "/works",
        fr: "/fr/works",
      },
      tft_title: {
        en: "Fair Travel App",
        fr: "App Voyage",
      },
      tft_category: {
        en: "Web Application",
        fr: "Application Web",
      },
      tft_link: {
        en: "/works/tft",
        fr: "/fr/works/tft",
      },
      ehl_title: {
        en: "VR Restaurant",
        fr: "Restaurant VR",
      },
      ehl_category: {
        en: "Virtual Reality Website",
        fr: "Site Réalité Virtuelle",
      },
      ehl_link: {
        en: "/works/vr-restaurant",
        fr: "/fr/works/vr-restaurant",
      },
      lyr_app_title: {
        en: "Impact Investment App",
        fr: "App Finance",
      },
      lyr_app_category: {
        en: "Web Application",
        fr: "Application Web",
      },
      lyr_app_link: {
        en: "/works/lyrique-app",
        fr: "/fr/works/lyrique-app",
      },
      cos_title: {
        en: "E-Commerce Website",
        fr: "Site E-Commerce",
      },
      cos_category: {
        en: "Website & Online Shop",
        fr: "Site web et e-shop",
      },
      cos_link: {
        en: "/works/cos",
        fr: "/fr/works/cos",
      },
      perle_title: {
        en: "Minimal Journaling App",
        fr: "App Journal Intime",
      },
      perle_category: {
        en: "Web Application",
        fr: "Application Web",
      },
      perle_link: {
        en: "/works/perle",
        fr: "/fr/works/perle",
      },
      ppi_title: {
        en: "Finance Website",
        fr: "Site Finance",
      },
      ppi_category: {
        en: "Website",
        fr: "Site Web",
      },
      ppi_link: {
        en: "/works/ppi",
        fr: "/fr/works/ppi",
      },
      services_title: {
        en: "Services.",
        fr: "Services",
      },
      services_text: {
        en:
          "Our team of experts work accross all digital mediums using only the latest tools and technologies. What do you need?",
        fr:
          "Notre équipe d'expert maitrise parfaitement les différentes plateformes et technologies pour un résultat exceptionel.",
      },
      services_web_title: {
        en: "Web",
        fr: "Web",
      },
      services_web_1: {
        en: "Design",
        fr: "Design",
      },
      services_web_2: {
        en: "Development",
        fr: "Développement",
      },
      services_web_3: {
        en: "MVP",
        fr: "MVP",
      },
      services_web_4: {
        en: "Static Websites",
        fr: "Site Web Statique",
      },
      services_web_5: {
        en: "E-Commerce",
        fr: "Boutique en Ligne",
      },
      services_web_6: {
        en: "Web Applications",
        fr: "Application Web",
      },
      services_mobile_title: {
        en: "Mobile Apps",
        fr: "Application Mobile",
      },
      services_mobile_1: {
        en: "Design",
        fr: "Design",
      },
      services_mobile_2: {
        en: "UI/UX",
        fr: "UI/UX",
      },
      services_mobile_3: {
        en: "Prototyping",
        fr: "Prototypage",
      },
      services_mobile_4: {
        en: "iOS / Android",
        fr: "iOS / Android",
      },
      services_mobile_5: {
        en: "Backend",
        fr: "Backend",
      },
      services_digital_title: {
        en: "Digital Strategy",
        fr: "Stratégie Digitale",
      },
      services_digital_1: {
        en: "SEO",
        fr: "SEO",
      },
      services_digital_2: {
        en: "Content Strategies",
        fr: "Stratégies de Contenu",
      },
      services_digital_3: {
        en: "Social Media",
        fr: "Média Sociaux",
      },
      services_digital_4: {
        en: "Multi Channel",
        fr: "Multi Channel",
      },
      services_digital_5: {
        en: "Chatbots",
        fr: "Chatbots",
      },
      services_brand_title: {
        en: "Design",
        fr: "Design",
      },
      services_brand_1: {
        en: "Branding",
        fr: "Image de Marque",
      },
      services_brand_2: {
        en: "Logo",
        fr: "Logo",
      },
      services_brand_3: {
        en: "Corporate Identity",
        fr: "Corporate Identity",
      },
      services_brand_4: {
        en: "Business Cards",
        fr: "Cartes de Visite",
      },
      services_brand_5: {
        en: "Promotional",
        fr: "Promotionnel",
      },
      services_brand_6: {
        en: "UI/UX",
        fr: "UI/UX",
      },
      testi_title: {
        en: "They trust us.",
        fr: "Ils nous font confiance.",
      },
      testi_1: {
        en:
          "The webpanda team took the necessary time to carefully understand our vision and needs. We're delighted with the result.",
        fr:
          "L'équipe de webpanda a pris le temps nécessaire pour bien comprendre notre vision et nos besoins. Nous sommes ravis du résultat.",
      },
      testi_1_person: {
        en: "Jason - Susfinteq Ltd.",
        fr: "Jason - Susfinteq Ltd.",
      },
      testi_2: {
        en:
          "It was great to find a one stop shop for all our digital needs. They offered their expertise for our website, analytics and digital marketing campaigns.",
        fr:
          "C'était formidable de trouver une seule agence pour l'ensemble nos besoins numériques. Nous avons fait appel à leur expertise pour notre site web, les analyses et les campagnes de marketing numérique.",
      },
      testi_2_person: {
        en: "Florence - Animan Publications SA",
        fr: "Florence - Animan Publications SA",
      },
      testi_3: {
        en:
          "Webpanda delivered on its promise of a cutting-edge application, substantially improving our internal workflow.",
        fr:
          "Webpanda a créé pour nous une application de pointe, améliorant considérablement notre worflow de travail à l'interne.",
      },
      testi_3_person: {
        en: "Alexandre - Lyrique SA",
        fr: "Alexandre - Lyrique SA",
      },
      contact_title: {
        en: "Contact.",
        fr: "Contact.",
      },
      contact_text: {
        en:
          "Questions? Ideas? Get in touch, we love to discuss new exciting projects. Coffee is on us.",
        fr: "Une question? des idées? Dites-nous en plus autour d'un café.",
      },
      contact_placeholder_name: {
        en: "Name",
        fr: "Nom",
      },
      contact_placeholder_email: {
        en: "Email",
        fr: "Email",
      },
      contact_placeholder_message: {
        en: "Message",
        fr: "Message",
      },
      contact_button_text: {
        en: "Send",
        fr: "Envoyer",
      },
      contact_email: {
        en: "hello@webpanda.ch",
        fr: "hello@webpanda.ch",
      },
      contact_phone: {
        en: "+41 79 448 53 77",
        fr: "+41 79 448 53 77",
      },
      foot_col1_title: {
        en: "CONTACT",
        fr: "CONTACT",
      },
      foot_col1_1: {
        en: "+41 79 448 5377",
        fr: "+41 79 448 5377",
      },
      foot_col1_2: {
        en: "hello@webpanda.ch",
        fr: "hello@webpanda.ch",
      },
      foot_col2_title: {
        en: "FOLLOW US",
        fr: "SUIVEZ NOUS",
      },
      foot_col2_1: {
        en: "Instagram",
        fr: "Instagram",
      },
      foot_col2_1_link: {
        en: "https://www.instagram.com/webpanda.ch/",
        fr: "https://www.instagram.com/webpanda.ch/",
      },
      foot_col2_2: {
        en: "Facebook",
        fr: "Facebook",
      },
      foot_col2_2_link: {
        en: "https://www.facebook.com/pg/webpanda.ch",
        fr: "https://www.facebook.com/pg/webpanda.ch",
      },
      foot_col2_3: {
        en: "Medium",
        fr: "Medium",
      },
      foot_col2_3_link: {
        en: "https://medium.com/",
        fr: "https://medium.com/",
      },
      foot_col3_title: {
        en: "OTHER",
        fr: "AUTRE",
      },
      foot_col3_1: {
        en: "Team",
        fr: "Team",
      },
      foot_col3_1_link: {
        en: "/team",
        fr: "/fr/team",
      },
      foot_col3_2: {
        en: "Works",
        fr: "Projets",
      },
      foot_col3_2_link: {
        en: "/works",
        fr: "/fr/works",
      },
      foot_col3_3: {
        en: "Services",
        fr: "Services",
      },
      foot_col3_3_link: {
        en: "/services",
        fr: "/fr/services",
      },
      foot_col3_4: {
        en: "Privacy policy",
        fr: "Politique de Confidentialité",
      },
      foot_col3_4_link: {
        en: "/privacy",
        fr: "/fr/privacy",
      },
      foot_text: {
        en:
          "Webpanda is a Swiss agency specialized in all things digital. Let us help you with your next website, application or digital strategy.",
        fr:
          "Webpanda est une agence suisse spécialisée dans le digital. Laissez-nous vous aider avec votre prochain site web, application ou stratégie digitale.",
      },
      services1_title: {
        en: "Websites",
        fr: "Site Web",
      },
      services1_text: {
        en:
          "At webpanda, we like to build modern and visually strong websites using only the latest technologies. Wether it be a simple corporate site or the next facebook, we've got you covered.",
        fr:
          "Chez webpanda, nous créons de toutes pièces des sites à l'identité forte utilisant uniquement les dernières technologies. Simple site statique ou le prochain facebook, dites-nous ce qu'il vous faut.",
      },
      services1_reference: {
        en: "See the website we built for ",
        fr: "Voir le site que l'on a créé pour ",
      },
      services1_reference_project: {
        en: "Animan",
        fr: "Animan",
      },
      services1_linkto: {
        en: "/works/animan",
        fr: "/fr/works/animan",
      },
      services2_title: {
        en: "Applications",
        fr: "Applications",
      },
      services2_text: {
        en:
          "While building mobile or web applications, our philosophy is always to put the user at the center of the process and solve his needs in the most elegant and simple way.",
        fr:
          "Lors de la création d'applications webs ou mobiles, notre philosophie est toujours de mettre l'utilisateur au centre afin de cibler ces besoins de façon simple et élégante.",
      },
      services2_reference: {
        en: "See the app we built for ",
        fr: "Voir l'application que l'on a créé pour ",
      },
      services2_reference_project: {
        en: "the fair traveller",
        fr: "the fair traveller",
      },
      services2_linkto: {
        en: "/works/tft",
        fr: "/fr/works/tft",
      },
      services3_title: {
        en: "Digital Strategy",
        fr: "Stratégie Digitale",
      },
      services3_text: {
        en:
          "Growing and improving your digital presence is one of the most direct ways of growing your business. At webpanda we use a multi channel approach optimized for your specific goals.",
        fr:
          "Développer et améliorer sa précense en ligne c'est une des meilleures façons de faire progresser son entreprise. Chez webpanda, l'on utilise une méthode multi channel optimisée à vos besoins spécifiques.",
      },
      services3_reference: {
        en: " ",
        fr: " ",
      },
      services3_reference_project: {
        en: " ",
        fr: " ",
      },
      services3_linkto: {
        en: " ",
        fr: " ",
      },
      services4_title: {
        en: "Design",
        fr: "Design",
      },
      services4_text: {
        en:
          "Nowadays, beautiful and functional design has become a must have. Wether it be corporate branding or UI/UX for your next application, let our designers guide you in this process.",
        fr:
          "De nos jours, une design modern et fonctionnel est une nécessité. Que ce soit votre identité de marque ou le UI/UX de votre prochaine application, laissez nos designers vous guider dans votre démarche.",
      },
      services4_reference: {
        en: "See the design system we built for ",
        fr: "Voir le design system que l'on a créé pour ",
      },
      services4_reference_project: {
        en: "Susfinteq",
        fr: "Susfinteq",
      },
      services4_linkto: {
        en: "/works/susfinteq",
        fr: "/fr/works/susfinteq",
      },
      work_tft_title: {
        en: "the fair traveller",
        fr: "the fair traveller",
      },
      work_tft_subtitle: {
        en: "web application",
        fr: "application web",
      },
      work_ehlvr_title: {
        en: "VR Restaurant",
        fr: "Restaurant VR",
      },
      work_ehlvr_subtitle: {
        en: "virtual reality website",
        fr: "site réalité virtuelle",
      },

      about_title: {
        en: "About webpanda.",
        fr: "A propos de webpanda.",
      },
      about_text: {
        en:
          "We're a Swiss digital agency focused on building elegant technological solutions for our clients. Our strength comes from our multi-disciplinary team, allowing us to cover the full spectrum of digital creation.",
        fr:
          "Nous sommes une agence suisse spécialisée dans le digital. Notre force, c'est notre équipe pluri-disciplinaires qui permet de couvrir tout le spectre de la création digitale et technologique.",
      },
      team_title: {
        en: "Our Team.",
        fr: "Notre Team.",
      },
      team_subtitle: {
        en:
          "From software engineers to design geeks, discover our coffee fueled A-Team. ",
        fr:
          "Ingénieur Informatique ou encore geek du design, découvrez notre A-Team.",
      },
      team_member1_name: {
        en: "Adrien",
        fr: "Adrien",
      },
      team_member1_title: {
        en: "Software Development",
        fr: "Développement Full Stack",
      },
      team_member2_name: {
        en: "Vincent",
        fr: "Vincent",
      },
      team_member2_title: {
        en: "Digital Marketing",
        fr: "Digital Marketing",
      },
      team_member3_name: {
        en: "Alexandre",
        fr: "Alexandre",
      },
      team_member3_title: {
        en: "Product",
        fr: "Product",
      },
      team_member4_name: {
        en: "Victor",
        fr: "Victor",
      },
      team_member4_title: {
        en: "Design",
        fr: "Design",
      },
      tft_perk1_title: {
        en: "Powerful search",
        fr: "Recherche intuitive.",
      },
      tft_perk1_text: {
        en:
          "Find #fairspots by using the list search or looking directly on the map. Use filters if needed.",
        fr:
          "Trouvez des #fairspots en utilisant la recherche liste ou directement sur la carte interactive. Filtrer les résultats au besoin.",
      },
      tft_perk2_title: {
        en: "Visual layout.",
        fr: "Visuelle.",
      },
      tft_perk2_text: {
        en:
          "Get a feel for your neighborhood cafe or bar by looking at pictures uploaded by the owner.",
        fr:
          "Découvrez l'ambiance du lieu grace aux images mises en lignes par le propriétaire.",
      },
      tft_perk3_title: {
        en: "Honest reviews.",
        fr: "Commentaires honnêtes.",
      },
      tft_perk3_text: {
        en:
          "Users who signed up can instantly provide feedback and join the conversation using the review system.",
        fr:
          "Les utilistateurs enregistrés peuvent instantanément donner leurs opinions à l'aide du système de commentaires. ",
      },
      tft_about_title: {
        en: "Travelling differently.",
        fr: "Voyager différemment.",
      },
      tft_about_p1: {
        en:
          "The fair traveller is the first crowd sourced platform connecting sustainable tourism providers with like minded travellers. The platform puts forward the positive actions each of these tourism actors are taking, allowing for a more sustainable way of travelling.",
        fr:
          "The fair traveller est la première platforme crowd sourcée qui met en relations les acteurs du tourisme durable avec une communauté de voyageurs partageant les mêmes valeurs.",
      },
      tft_about_p2: {
        en:
          "For this project, webpanda created a modern web application with a strong emphasis on community and transparency. Users can easily discover, review and add #fairspots to the platform.",
        fr:
          "Pour ce projet, webpanda a créé une application web moderne construite autour du concept de tranparence et communauté. Les utilisateurs peuvent facilement découvrir, ajouter et contacter des #fairspots.",
      },
      ehlvr_about_title: {
        en: "An immersive experience.",
        fr: "Une expérience immersive.",
      },
      ehlvr_about_p1: {
        en:
          "For their final year consulting project, students from EHL were tasked to design a new restaurant concept. In order to present their concept to stakeholders, they decided to user Virtual Reality.",
        fr:
          "Dans le cadre de leurs projets final de consulting, des élèves de l'EHL ont du créer un concept restaurant de toutes pièce pour un client. Afin de présenter le rendu final du projet, ils ont décidé d'utiliser la Réalité Virtuelle.",
      },
      ehlvr_about_p2: {
        en:
          "For this project, webpanda decided to user cutting edge WebVR technology, allowing users to immerse themselves using nothing more than a mobile phone and a cheap cardboard head set.",
        fr:
          "Pour ce mandat, webpanda a utilisé la technologie de VR pour le web appellée WebVR. Elle permet d'immerger l'utilisateur du site grâce à n'importe quel smartphone et de lunettes VR en carton.",
      },
      ehlvr_perk1_title: {
        en: "VR on your phone.",
        fr: "VR sur votre téléphone",
      },
      ehlvr_perk1_text: {
        en:
          "Using WebVR allows to experience VR from a mobile browser using a cardboard headset.",
        fr:
          "La technologie WebVR permet de s'immerger simplement à l'aide d'un smartphone et lunette VR en carton",
      },
      ehlvr_perk2_title: {
        en: "A detailed scene.",
        fr: "Scène détaillée.",
      },
      ehlvr_perk2_text: {
        en:
          "Using the actual plans for the restaurant, we recreated every detail including furniture, textures and plants.",
        fr:
          "En utilisant les plans du restaurant, nous avons recrééer chaque détail de la scène tels que les meubles, textures et plantes.",
      },
      work_lyrapp_title: {
        en: "Investment Dashboard",
        fr: "Dashboard Financier",
      },
      work_lyrapp_subtitle: {
        en: "web application",
        fr: "application web",
      },
      lyrapp_about_title: {
        en: "Investing with impact.",
        fr: "Investir avec impacte.",
      },
      lyrapp_about_p1: {
        en:
          "Lyrique, a private equity specialist in Geneva approached us with the idea of finding an elegant solution to streamline their impact investment process.",
        fr:
          "Lyrique, une société genevoise spécialiste du Private Equity a fait appelle à nos services pour la création d'une solutions technologique afin d'optimiser leur processus d'investissement impact.",
      },
      lyrapp_about_p2: {
        en:
          "Based on the initial requirements, webpanda created a secure internal application to track, search for and archive key investment data.",
        fr:
          "A partir d'une analyse de leurs besoins, webpanda a créé une aplication cloud sécurisée permettant la saisie, recherche et l'archivage de leurs données d'investissement.",
      },
      lyrapp_perk1_title: {
        en: "Login from anywhere.",
        fr: "Connection où que vous soyez.",
      },
      lyrapp_perk1_text: {
        en:
          "The application is cloud based, allowing users to securely login from any device, anywhere.",
        fr:
          "L'application est hébergée dans le cloud, ce qui permet aux utilisateurs d'y avoir accès à tout moment sur n'importe quel appareil.",
      },
      lyrapp_perk2_title: {
        en: "Organized data.",
        fr: "Données organisées.",
      },
      lyrapp_perk2_text: {
        en:
          "The design of the application is closely tailored to the needs of the day to day workflows.",
        fr:
          "La structure des données est adaptée sur mesure à la façon dont elles vont être utilisées.",
      },
      lyrapp_perk3_title: {
        en: "Find what you need.",
        fr: "Cherchez et trouvez.",
      },
      lyrapp_perk3_text: {
        en:
          "A custom filtering module was added, to allow for an optimal query of the investment data.",
        fr:
          "Une module de filtrage sur mesure à été ajouté à l'application, afin d'optimiser le temps de recherche.",
      },
      work_ppi_title: {
        en: "Forex Company",
        fr: "Société Financiaire",
      },
      work_ppi_subtitle: {
        en: "corporate website",
        fr: "site web",
      },
      ppi_about_title: {
        en: "A fresh image.",
        fr: "Une nouvelle image.",
      },
      ppi_about_p1: {
        en:
          "PPI, a leading Swiss firm in the Foreign Currency market approached us to develop their new online image by creating a modern website.",
        fr:
          "PPI, une société genevoise active dans le Forex voulais rafraîchir son image et créer une nouvelle monture web pour l'occasion.",
      },
      ppi_about_p2: {
        en:
          "We approached the challenge with 3 core drivers: amplifying the brand, adding a modern touch and respecting the industry values.",
        fr:
          "L'approche de webpanda s'est basée sur 3 principes fondamentaux: amplifier la marque, ajouter une touche moderne et respecter les valeurs et code de l'industrie.",
      },
      ppi_perk1_title: {
        en: "Strong brand.",
        fr: "Marque forte.",
      },
      ppi_perk1_text: {
        en:
          "Throughout the website, the PPI brand is put forward through choice of colors and visuals.",
        fr:
          "On retrouve à travers le choix de couleurs et images du site l'identité forte de la marque PPI.",
      },
      ppi_perk2_title: {
        en: "Modern medium.",
        fr: "Media moderne.",
      },
      ppi_perk2_text: {
        en:
          "The use of video content throughout the website allows to convey complex topics in simple format.",
        fr:
          "L'ajout de vidéo au site a permis d'expliquer certains concepts techniques de façon claire.",
      },
      ppi_perk3_title: {
        en: "Interactive experience.",
        fr: "Une expérience intéractive. ",
      },
      ppi_perk3_text: {
        en:
          "The website is dynamic and allows the user to navigate in an intuitive way, easily finding the needed information.",
        fr:
          "Le site web utilise des modules intéractif pour permettre à l'utilisateur de naviguer de façon intuitive.",
      },
      work_cos_title: {
        en: "Fashion brand",
        fr: "Marque de mode",
      },
      work_cos_subtitle: {
        en: "website & e-commerce",
        fr: "site web et boutique en ligne",
      },
      cos_about_title: {
        en: "Minimal.",
        fr: "Minimaliste.",
      },
      cos_about_p1: {
        en:
          "Church of Soho, a London art collaborative needed to build a new website. Given their minimal aesthetics, the website would also have to reflect this.",
        fr:
          "Church of Soho est un collectif d'art basé à Londres. Pour leurs nouveau site, il voulait une monture qui reflete leur esthetique minimale.",
      },
      cos_about_p2: {
        en:
          "Using only black and white and very little text, the result is a canvas like display that allows their work to shine through.",
        fr:
          "En restant sur des teintes neutres et gardant les textes simples, le résultat resemble à une toile vierge, mettant en avant leurs créations.",
      },
      cos_perk1_title: {
        en: "Collection viewer.",
        fr: "Page Collection.",
      },
      cos_perk1_text: {
        en:
          "The collection page allows the user to browse through available pieces and redirect to the shop.",
        fr:
          "La page collection permet à l'utilisateur de parcourir les dernières créations et naviguer à la boutique.",
      },
      cos_perk2_title: {
        en: "Up close.",
        fr: "Vue détaillée.",
      },
      cos_perk2_text: {
        en:
          "As every item is unique, a zoomed in view allows shoppers to pick the perfect piece.",
        fr:
          "Comme chaque pièce est unique, une vue agrandi permet de découvrir les détails de près.",
      },
      cos_perk3_title: {
        en: "Gallery like.",
        fr: "Telle une gallerie.",
      },
      cos_perk3_text: {
        en:
          "The simplified aesthetics are reminiscent of an art gallery, bringing the spotlight to the works.",
        fr: "L'esthétique minimaliste rappelle une gallerie d'art.",
      },
      work_perle_title: {
        en: "Daily Journal",
        fr: "Journal Intime",
      },
      work_perle_subtitle: {
        en: "web application",
        fr: "application web",
      },
      perle_about_title: {
        en: "Simple journaling.",
        fr: "L'essentiel de votre journée.",
      },
      perle_about_p1: {
        en:
          "The idea behind perle was to create a simple and clear journaling application, that allowed for nothing more than text. Every day is represented by a pearl or perle in french.",
        fr:
          "L'idée de l'application perle est de créer un journal intime en ligne épuré. La saisie est limitée au texte et chaque jour de l'année représenté par une perle.",
      },
      perle_about_p2: {
        en:
          "Over time, a user can track how often he journaled and see all days of the year in a grid like display.",
        fr:
          "Au fil de du temps, l'utilisateur peut garder compte des jours où il a pris des notes et les relire quand il veut.",
      },
      perle_perk1_title: {
        en: "Secure.",
        fr: "Sécurisée.",
      },
      perle_perk1_text: {
        en: "Every user can login using their own secure credentials.",
        fr:
          "Chaque utilisateur peut créer un compte et s'authentifier de manière sécurisée.",
      },
      perle_perk2_title: {
        en: "Yearly progress.",
        fr: "Progrès annuel.",
      },
      perle_perk2_text: {
        en:
          "See your journaling trend in the grid, and make it a habit to log something everyday.",
        fr:
          "Prenez note des jours où vous avez écris, et faites en une habitude.",
      },
      perle_perk3_title: {
        en: "Words on paper.",
        fr: "Juste des mots.",
      },
      perle_perk3_text: {
        en:
          "No pictures, adds, or other distractions. Just your thoughts on the page, securely saved.",
        fr:
          "Aucunes images, publicités où autres distractions. Juste vos idées sur la page.",
      },
      sus_title: {
        en: "Fintech Design Guide",
        fr: "Design System Fintech",
      },
      sus_category: {
        en: "UI/UX Design",
        fr: "Design UI/UX",
      },
      sus_link: {
        en: "/works/susfinteq",
        fr: "/fr/works/susfinteq",
      },
      work_susfinteq_title: {
        en: "Fintech Startup",
        fr: "Startup Fintech",
      },
      work_susfinteq_subtitle: {
        en: "UI/UX design",
        fr: "design UI/UX",
      },
      susfinteq_about_title: {
        en: "Performance and Sustainability.",
        fr: "Performance et durabilité.",
      },
      susfinteq_about_p1: {
        en:
          "Susfinteq is pioneering the use of A.I and Machine Learning in the field of Sustainable Finance. Their approach allows to generate thousands of ESG scores in real time.",
        fr:
          "Susfinteq développe une approche novatrice dans le domaine de la Finance durable en utilisant l'Intelligence Artificielle. Leur approche permet de générer des milliers de scores ESG en temps réel.",
      },
      susfinteq_about_p2: {
        en:
          "A startup with a bold vision needs the strong identity that comes with it. To create it, we worked closely with the founders to infuse elements of technology, finance and sustainability.",
        fr:
          "Il fallait pour cette société innovante une forte identité de marque. Pour cela, nous avons travaillé de près avec les co-fondateurs pour y infuser des éléments de technologie, finance et durabilité.",
      },
      susfinteq_perk1_title: {
        en: "A shared DNA.",
        fr: "ADN partagée.",
      },
      susfinteq_perk1_text: {
        en:
          "The created style guide reflects the core values that are technological innovation and sustainbility for everyone.",
        fr:
          "Le styleguide créé reflète les valeurs fortes de Susfinteq: la technologie, l'innovation et la durabilité pour tout le monde.",
      },
      susfinteq_perk2_title: {
        en: "Homogenous Experience.",
        fr: "Expérience homogène.",
      },
      susfinteq_perk2_text: {
        en:
          "The stlye guide allowed to align all of Susfinteq's products and platforms to reflect the brands values and feel.",
        fr:
          "Cette approche a permis d'alligner l'identité visuelle de tout les produits et plateformes de Susfinteq.",
      },
      ani_title: {
        en: "Travel Magazine",
        fr: "Magazine de Voyage",
      },
      ani_category: {
        en: "Website",
        fr: "Site Web",
      },
      ani_link: {
        en: "/works/animan",
        fr: "/fr/works/animan",
      },
      work_animan_title: {
        en: "Travel Magazine",
        fr: "Magazine de Voyage",
      },
      work_animan_subtitle: {
        en: "website",
        fr: "site web",
      },
      animan_about_title: {
        en: "Beauty through stories.",
        fr: "Les beautés du monde.",
      },
      animan_about_p1: {
        en:
          "With about 100'000 readers in Switzerland, Animan Magazine has delighted travel and photography enthusiats for over 30 years.",
        fr:
          "Avec plus de 100'000 lecteurs en Suisse, Animan Magazine a ravis les amateurs de voyage et photographie depuis plus de 30 ans.",
      },
      animan_about_p2: {
        en:
          "For the creation of the new Animan website, the brief was to create the platform to propulse the magazine into the digital era. This would mean using this new medium to share the Animan experience.",
        fr:
          "Pour la création du nouveau site Animan, le brief était de crééer une plateforme pour propulser le magazien dans l'ère digitale. Un nouveau medium pour partager l'expérience Animan.",
      },
      animan_perk1_title: {
        en: "Visually clean.",
        fr: "Visuellement fort.",
      },
      animan_perk1_text: {
        en:
          "The clean and pure visuals of the magazine identity are reflected in the homepage.",
        fr: "Le visuel épuré du magasine se retrouve dans la page d'accueil.",
      },
      animan_perk2_title: {
        en: "Portfolios.",
        fr: "Portfolios.",
      },
      animan_perk2_text: {
        en:
          "Animans signature Portfolio images are now available directly online after each release.",
        fr:
          "Le portfolio photo culte Animan est maintenant disponible en ligne après chaque numéro.",
      },
      animan_perk3_title: {
        en: "Explore.",
        fr: "Explorations.",
      },
      animan_perk3_text: {
        en:
          "Animan readers can now discover extracts of all new published articles online.",
        fr:
          "Les lecteurs peuvent maintenant découvrir des extraits de nouveaux articles directement en ligne.",
      },
      form_success: {
        en: "Thank you, we've received your message!",
        fr: "Merci, nous avons reçu votre message!",
      },
      form_fail: {
        en: "Oops, something went wrong. Reload the page and try again.",
        fr:
          "Oops, le message n'a pas été envoyé. Ressayez dans quelques minutes.",
      },
      form_validation_name: {
        en: "Please let us know who we're helping",
        fr: "N'oubliez pas votre nom dans le formulaire.",
      },
      form_validation_email: {
        en: "Please let us know how to contact you.",
        fr: "N'oubliez pas votre email dans le formulaire.",
      },
      form_validation_message: {
        en: "Please let us know how we can help",
        fr: "N'oubliez pas de nous laisser un message",
      },
    }
  }

  getTrans(key) {
    if (this.translations[key] && this.translations[key][this.lang]) {
      return this.translations[key][this.lang]
    } else {
      return "invalid key"
    }
  }
}
